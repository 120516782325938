import tw from "tailwind-styled-components";


export const Loading = tw.div`
flex 
justify-center 
items-center 
pt-5
`

export const PostGrid = tw.div`
grid
grid-cols-1	
gap-8
justify-self-center	
tablet:grid-cols-2
desktop:grid-cols-3	
px-2
${({ $sidebar }) => $sidebar && "!grid-cols-1"}
`

export const BlogWrapper = tw.div`
flex
flex-col
gap-12
max-w-[1128px]
w-full
justify-center
align-center
pb-8
tablet:px-4
mb-8
`
export const BlogTitle = tw.h1`
text-4xl
!text-primary-800
text-center
leading-tight
text-pretty	
tablet:text-[3.5rem]
`

export const SectionTitle = tw.div`
  flex
  flex-col
  items-center
  overflow-hidden

  [&_h2]:text-[22px]
  [&_h2]:leading-[30px]
  [&_h2]:mb-[var(--spacingM)]
  [&_h2]:text-center
  ${({ $bold }) => $bold && "  [&_h2]:font-medium"}


  [&_hr]:w-[128px]
  [&_hr]:border-t-[4px]
  [&_hr]:border-primary800
  [&_hr]:mb-[var(--spacingL)]

  tablet:[&_h2]:text-[36px]
  tablet:[&_h2]:leading-[40px]


  [&_h4]:text-[20px]
  [&_h4]:mb-[var(--spacingM)]
  [&_h4]:text-left
  [&_h4]:font-medium	
  [&_h4]:mr-auto
`;

export const SectionDescription = tw.div`
  text-base
  [&_p]:m-0
  mb-8
  text-center
`;

export const PostWrapper = tw.div`
w-fit
realative
grid
grid-cols-1
gap-4
tablet:grid-cols-[2fr,1fr]
mb-10
py-4
`
export const PostContent = tw.div`
flex
flex-col
gap-6
`
export const PostTitle = tw.h1`
text-4xl	
tablet:text-[56px]
text-balance	
text-primary-800
leading-snug	
`
export const PostAuthorWrapper = tw.div`
flex
gap-2
items-center
`
export const PostAuthor = tw.p`
text-lg
text-balance	
text-gray-700
`
export const PostAuthorImage = tw.img`
w-6
h-6
object-cover 
rounded-full
`

export const PostDate = tw.p`
text-lg
text-balance	
text-gray-700
`

export const PostFeaturedImage = tw.img`
w-full
object-cover 
rounded-3xl
aspect-[3/1.3]
`

export const PostDescription = tw.div`
[&_h2]:text-4xl
[&_h2]:text-primary-800
[&_h2]:font-medium	
[&_h2]:mb-4	
[&_h2]:leading-[48px]
[&_img]:mb-4	

[&_h3]:text-[22px]
[&_h3]:leading-8	
[&_h3]:text-primary-800
[&_h3]:mb-4

[&_p]:text-base	
tablet:[&_p]:text-xl	
[&_p]:mb-4

[&_ul]:mb-4
[&_ul]:pl-10
[&_ul]:list-disc	

[&_li]:text-base	
tablet:[&_li]:text-xl	

[&_a]:underline
`

export const SocialShareWrapper = tw.div`
flex
gap-2
items-center
[&_svg]:w-8
`
export const SocialShareTitle = tw.div`
text-gray
font-medium
`

export const PostSidebar = tw.div`
sticky
top-24
h-fit	
flex
flex-col
gap-12
`

export const MoreInfo = tw.div`
p-5
flex
flex-col
gap-4
bg-secondary-100
rounded-lg
`

export const RelatedWrapper = tw.div`
mt-6
col-span-2
flex
flex-col
gap-4
pb-20
border-[#CCCCCC]
${({ $sidebar }) => $sidebar && "border-b pb-8"}

`

export const RelatedPostWrapper = tw.div`
flex
flex-col
gap-8
items-center
border-t
border-gray-200
pt-4
tablet:flex-row
${({ $col }) => $col && "tablet:flex-col"}

`

export const RelatedPost = tw.div`
flex
gap-2
bt
`

export const RelatedTitle = tw.div`
text-lg	
text-cta-800
`


export const RelatedImage = tw.img`
w-14
object-cover 
rounded-lg
aspect-[1/1]
max-w-[72px]
`

export const AllPosts = tw.p`
mt-6
text-center
text-cta-800
border
py-2
border-primary
rounded-lg
max-w-96
mx-auto
`

export const AuthorWrapper = tw.div`
flex
gap-4
bg-[#EFF5F5]
px-6
py-8
rounded-lg
border
border-[#CCE0DF]
`
export const AuthorImage = tw.img`
w-16
h-16
object-cover 
rounded-lg
`
export const AuthorContent = tw.div`
flex
flex-col
gap-2
`
export const AuthorName = tw.p`
text-lg	
font-semibold	
`

export const AuthorDescription = tw.p`
text-lg	
font-normal	
`

export const CategoriesListWrapper = tw.div`
border
border-gray-200
rounded-xl
px-5
tablet:border-none
${({ $sidebar }) => $sidebar && "px-0"}
`
export const CategoriesHeader = tw.div`
flex
gap-4
align-center
items-center
justify-between
py-4
[&_svg]:text-primary-800
tablet:hidden
px-4
`

export const CategoriesTitleIconWrapper = tw.div`
flex
gap-4
align-center
items-center
`

export const CategoriesItems = tw.ul`
hidden
gap-4
flex-col
py-4
list-disc	
list-inside	
border-t
border-gray-200
px-4
${({ $isOpen }) => $isOpen && "flex"}
tablet:list-none
tablet:flex
tablet:flex-row
tablet:flex-wrap
tablet:items-center
tablet:justify-center
tablet:border-none
${({ $sidebar }) => $sidebar && "tablet:justify-start tablet:px-0"}
`
export const CategoriesTitle = tw.p`
text-lg	

`
export const CategoryName = tw.li`
text-sm	
text-primary-800
cursor-pointer

tablet:border
tablet:border-primary-800
tablet:rounded-3xl
tablet:px-3
tablet:py-2

hover:bg-primary-800 
hover:text-white
`
