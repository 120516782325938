import React from 'react';
import PostItem from './post-item';
import { PostGrid } from './styles';

const PostList = ({ posts }) => {
  return (
    <PostGrid>
      {posts.map((post, index) => (
        <PostItem key={index} post={post} />
      ))}
    </PostGrid>
  );
};

export default PostList;