import React from 'react';
import Link from 'next/link';
import {
  BlogItemGridWrapper,
  BlogItemWrapper,
  BlogItemInfoWrapper,
  TitleWrapper,
  DescriptionWrapper,
  CardButton,
} from '../elements/blog-item/styles';
import { ButtonCustomHex } from '../elements';
import { getFormatedDate } from '../../utils';

const hexCode = "#004039";

const PostItem = ({
  post
}) => {
  const { slug, date, title } = post;
  let imageUrl;

  if (post._embedded?.['wp:featuredmedia']) {
    const featuredMedia = post._embedded['wp:featuredmedia'][0];
    const mediaDetails = featuredMedia?.media_details;
    imageUrl = mediaDetails?.sizes?.medium?.source_url
      || mediaDetails?.sizes?.thumbnail?.source_url
      || featuredMedia?.source_url;
  }

  return (
    <BlogItemGridWrapper >
      <Link href={`/blog/${slug}`}>

        <BlogItemWrapper >
          <img
            src={imageUrl}
            alt={title.rendered}
            loading="lazy"
          />
          <BlogItemInfoWrapper >
            <div>
              <TitleWrapper hexCode={hexCode}>
                <h3>{title.rendered}</h3>
              </TitleWrapper>
              <DescriptionWrapper >
                {getFormatedDate(date, 'es')}
              </DescriptionWrapper>
            </div>
            <CardButton hexCode={hexCode} >
              <ButtonCustomHex hexCode={hexCode}>
                Leer más
              </ButtonCustomHex>
            </CardButton>
          </BlogItemInfoWrapper>
        </BlogItemWrapper>
      </Link>
    </BlogItemGridWrapper>
  );
};


export default PostItem;