import styled, { css } from 'styled-components';
import { NextImage } from '../../../elements';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: var(--spacingM);

  @media screen and (max-width: 1128px) {
    flex-direction: column;
  }
`;

export const FormSidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacingM);

  width: 100%;
  img{
    display:none;
  }
  p, h3{
    text-align: left;
  }
  hr{
    border-color: #E5E5E5;
  }

  @media screen and (max-width: 1128px) {
    display: none;
  }
`;

export const Subtitle = styled.h3`
  font-size: 30px;
  line-height: 40px;
  font-weight: normal;
  margin-bottom: var(--spacingM);
  display:block;

`;

export const SectionDescription = styled.div`
  font-size: var(--textS);
  line-height: var(--lineHeightS);
  margin-bottom: var(--spacingXs);

  p {
    margin: 0;
  }

  a {
    font-weight: bold;
    color: var(--gray);
    text-decoration-line: underline;
  }

  @media screen and (min-width: 1128px) {
    display: none;
  }
`;

export const Image = styled(NextImage)`
  border-radius: var(--spacingS);
  flex-grow: 2;
  object-fit: cover;
  display: none;

  @media screen and (min-width: 1128px) {
    display: block;
    aspect-ratio: 3/2;
  }
`;

export const CtaWrapper = styled.div`
  display: none;
  flex-direction: column;
  gap: var(--spacingS);
  padding: var(--spacingS);
  margin: 0;
  background-color: var(--gray100);
  border-radius: var(--spacingS);
  max-height: 312px;

  @media screen and (min-width: 1128px) {
    display: flex;
    justify-content: center;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      max-height: unset;
      @media screen and (min-width: 1128px) {
        display: none;
      }
    `}
`;

export const CtaTitle = styled.h3`
  font-size: var(--textM);
  line-height: var(--lineHeightM);
  font-weight: 500;
  margin: 0;
  text-align:center;

`;

export const CtaDescription = styled.p`
  font-size: var(--textS);
  line-height: var(--lineHeightS);
`;

export const CtaDescriptionDesktop = styled.div`
  font-size: var(--textS);
  line-height: var(--lineHeightS);
`;

export const PhoneNumberLink = styled.span`
  font-weight: bold;
  text-underline-offset: 2px;
`;

export const Description = styled.p`
  font-size: var(--textS);
  line-height: var(--lineHeightS);
  margin-bottom: var(--spacingM);
  text-align: left;
`;

export const Form = styled.div`
  background-color: var(--gray100);
  padding: var(--spacingM);
  border-radius: var(--spacingS);

  @media screen and (min-width: 1128px) {
    grid-column: span 2;
    background-color: var(--gray100);
    border-radius: var(--spacingS);
    padding: calc(var(--spacingXs) * 2) calc(var(--spacingS) * 2);
      width: 100%;
  }
`;

export const CtaTitleMobile = styled.div`
  font-size: var(--textM);
  line-height: var(--lineHeightM);
  font-weight: normal;
  margin: 0;
  P{
    margin: 0;
  }

  @media screen and (min-width: 1128px) {
    font-size: var(--text2L);
    line-height: var(--lineHeight2L);
  }
`;

export const CtaDescriptionMobile = styled.div`
  font-size: var(--textS);
  line-height: var(--lineHeightS);
  p{
    margin: 10px 0;
  }
`;
