import { useState } from 'react'
import { CategoriesHeader, CategoriesListWrapper, CategoriesTitleIconWrapper, CategoriesItems, CategoriesTitle, CategoryName } from './styles'
import { ListBulleted, ChevronUp, ChevronDown } from '@carbon/icons-react'
import Link from 'next/link';

export const CategoriesList = ({ categories, sidebar = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (

        <CategoriesListWrapper $sidebar={sidebar}>
            <CategoriesHeader onClick={() => setIsOpen(!isOpen)}>
                <CategoriesTitleIconWrapper>
                    <ListBulleted size={24} />
                    <CategoriesTitle>Categorías</CategoriesTitle>
                </CategoriesTitleIconWrapper>
                {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
            </CategoriesHeader>
            <CategoriesItems $isOpen={isOpen} $sidebar={sidebar}>
                {categories.map((cat, id) => (
                    <Link href={`/blog/categoria/${cat.slug}`} key={id}>
                        <CategoryName>
                            {cat.name}
                        </CategoryName>
                    </Link>
                ))}
            </CategoriesItems>
        </CategoriesListWrapper>)
}