import styled, { css } from "styled-components";

export const BlogItemGridWrapper = styled.li`
  --cardItemImageHeight: 221px;
  --cardItemDescription: 128px;
  --cardItemTitle: 92px;

  list-style: none;
  width: 100%;
  border-radius: 16px;
  flex-shrink:0;
  max-width:300px;

  > a {
    margin-bottom: 0px;
  }

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      display: none;
  `}

  @media screen and (min-width: 1128px) {
    display: list-item;
    max-width:360px;
    &:hover{
      box-shadow: 0px 4px 30px 0px #00000040;
    }
  }
`;

export const BlogItemInfoWrapper = styled.div`
  position: relative;
  flex-grow:2;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacingM);
  gap: var(--spacingM);
  border: 1px solid #B3B3B3;
  border-radius: 0 0 16px 16px;
  border-top:none;
  @media screen and (max-width: 400px) {
    padding: var(--spacingS);
    gap: var(--spacingS);
  }

  @media screen and (min-width: 1128px) {
    padding: var(--spacingM);
    gap: var(--spacingS);
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
  word-break: break-word;

  > h3 {
    margin: 0;
    font-size: var(--textM);
    color: ${({ hexCode }) =>
    hexCode ? hexCode : "var(--primary800)"};
    font-weight: 500;

    > a {
      text-decoration: none;
    }
  }

  @media screen and (min-width: 1128px) {

    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    > h3 {
      padding: 0px;
      font-size: 20px;
      line-height: normal;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #4D4D4D;
  font-size: 16px;
  line-height: var(--lineHeightS);
  min-height: 60px;
  padding: 0px;
  margin: 0px;
  margin-top: 8px;
  ul {
    gap: 0;
  }

  > p {
    padding: 0px;
    margin: 0px;
  }

  @media screen and (min-width: 1128px) {
    display: flex;
    overflow: hidden;

    > p {
      display: -webkit-box;
      line-height: var(--lineHeightS);
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

  }
`;

export const BlogItemWrapper = styled.article`
  display: flex;
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  text-decoration: none;
  transition: transform 0.1s ease-in-out;
  text-decoration: none;
    height:100%;
  &[href]:focus,
  &[href]:hover {
    transform: scale(1.05);
  }

  > img {
    border-radius: var(--radiusL) var(--radiusL) 0 0;
    object-fit: cover;
    width: 100%;
    height: 160px;
  }

@media screen and (min-width: 1128px) {
    > img {
      width: 100%;
      height: var(--cardItemImageHeight);
      display: block;
    }
  }
`;

export const CardButton = styled.div`
  max-width: 228px;
  align-self: center;
  width: fit-content;
  cursor:pointer;
  > a {
    width: fit-content;
    height: 32px;
    font-size: var(--textS);
    color: white;
    background-color: ${({ hexCode }) =>
    hexCode ? hexCode : "var(--primary800)"};;
    border-radius: var(--radiusXs);
  }
  > div {
     border-radius: var(--radiusXs);
  }
`;
