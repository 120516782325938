import React, { useState, useEffect } from 'react';
import PostList from './post-list';
import { fetchPosts } from '../../lib/utils';
import { SectionTitle, AllPosts, RelatedImage, RelatedPost, RelatedPostWrapper, RelatedTitle, RelatedWrapper } from './styles';
import Link from 'next/link';

const mostViewedCategoryId = 1422

export const MostViewedPosts = ({ sidebar = false, preFetchedPosts = [] }) => {
    const [posts, setPosts] = useState(preFetchedPosts);
    useEffect(() => {
        if (preFetchedPosts.length === 0) {
            const fetchRelated = async () => {
                try {
                    const related = await fetchPosts(1, mostViewedCategoryId, 3);
                    setPosts(related);
                } catch (error) {
                    console.error('Failed to fetch related posts:', error);
                }
            };

            fetchRelated();
        }
    }, []);

    if (posts.length === 0) {
        return null;
    }

    return (
        <>
            {sidebar ? (
                <RelatedWrapper $sidebar={true}>
                    <h2>Artículos más leídos</h2>
                    <RelatedPostWrapper $col={true}>
                        {posts.map((post, index) => (
                            <Link href={`/blog/${post.slug}`} key={post.id}>
                                <RelatedPost>
                                    {post._embedded && <RelatedImage src={post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url} alt={post.title.rendered} />}
                                    <RelatedTitle>{post.title.rendered}</RelatedTitle>
                                </RelatedPost>
                            </Link>
                        ))}
                    </RelatedPostWrapper>
                    <Link href={`/blog`}>
                        <AllPosts>Ver todos los artículos</AllPosts>
                    </Link>
                </RelatedWrapper>
            ) : (
                <div>
                    <SectionTitle $bold>
                        <h2>Lo más leído</h2>
                        <hr />
                    </SectionTitle>
                    <PostList posts={posts} />
                </div>
            )}
        </>
    );
};

export default MostViewedPosts;
