import {
    LogoFacebook,
    LogoTwitter,
    LogoLinkedin,
    Email
} from '@carbon/icons-react';
import { SocialShareWrapper, SocialShareTitle } from '../styles';
import { WhatsappIcon } from '../../../lib/icons';

export const SocialShareButtons = ({ url, title }) => {
    const shareUrl = encodeURIComponent(url);
    const shareTitle = encodeURIComponent(title);

    return (
        <>
            <SocialShareTitle>Compartir artículo</SocialShareTitle>
            <SocialShareWrapper>
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <LogoFacebook size="32" />
                </a>

                <a
                    href={`https://api.whatsapp.com/send?text=${shareTitle}%20${shareUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {WhatsappIcon}
                </a>

                <a
                    href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <LogoTwitter size="32" />
                </a>

                <a
                    href={`https://www.linkedin.com/shareArticle?url=${shareUrl}&title=${shareTitle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <LogoLinkedin size="32" />
                </a>

                <a
                    href={`mailto:?subject=${shareTitle}&body=${shareUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Email size="32" />

                </a>
            </SocialShareWrapper>
        </>
    );
};

export default SocialShareButtons;
