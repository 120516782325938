import { AuthorContent, AuthorDescription, AuthorImage, AuthorName, AuthorWrapper } from "../styles";

export const AuthorDetail = ({ author }) => {
    const featuredAuthorMedia = author?.avatar_urls ? author.avatar_urls[96] : null;
    const { name, user_bio } = author;

    return (
        <AuthorWrapper>
            {featuredAuthorMedia &&
                <AuthorImage
                    src={featuredAuthorMedia}
                    alt={name || null}
                    loading="lazy"
                />
            }
            <AuthorContent>
                {name &&
                    <AuthorName>
                        {name}
                    </AuthorName>
                }
                {user_bio &&
                    <AuthorDescription>
                        {user_bio}
                    </AuthorDescription>
                }
            </AuthorContent>
        </AuthorWrapper>
    )
}

export default AuthorDetail;