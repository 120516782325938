import dynamic from "next/dynamic";
import { renderLineBreaks } from "../../../../utils";
import { HUBSPOT_FORM_SOURCE } from "../../../hubspot-form/enums";
import {
  Wrapper,
  FormSidebar,
  Subtitle,
  Image,
  Description,
  PhoneNumberLink,
  Form,
  CtaWrapper,
  CtaDescription,
  CtaTitle,
  CtaTitleMobile,
  CtaDescriptionMobile,
  CtaDescriptionDesktop
} from "./styles";
import EditorJSContent from "../../../editor-js-content";

const HubspotForm = dynamic(() => import("../../../hubspot-form/hubspot-form"));
const Link = dynamic(() => import("../../../elements/link/link"));

export const SidebarForm = ({ form }) => {
  const {
    __typename,
    subtitle,
    description,
    image,
    ctaTitle,
    ctaDescription,
    ctaDescriptionDesktop,
    cta,
    descriptionPhoneNumberLink,
    ctaTitleMobile,
    ctaDescriptionMobile
  } = form;

  form.formId = 'e31a1176-ed18-4926-a1db-38961d764800'
  const hasCtaWrapper = ctaTitle || cta;
  return (
    <Wrapper id="form">
      <FormSidebar>
        {image && <Image media={image} />}
        {hasCtaWrapper && (
          <CtaWrapper>
            {ctaTitle && <CtaTitle>{renderLineBreaks(ctaTitle)}</CtaTitle>}
            <hr />
            {ctaDescriptionDesktop ? (
              <CtaDescriptionDesktop>
                <EditorJSContent content={ctaDescriptionDesktop} />
              </CtaDescriptionDesktop>
            ) : ctaDescription && (
              <CtaDescription>
                {renderLineBreaks(ctaDescription)}
              </CtaDescription>
            )}
          </CtaWrapper>
        )}
      </FormSidebar>
      <Form>
        {subtitle && <Subtitle>{renderLineBreaks(subtitle)}</Subtitle>}
        {description && (
          <Description >
            {renderLineBreaks(description)}
            {descriptionPhoneNumberLink && (
              <PhoneNumberLink>
                <Link
                  type="text"
                  text={descriptionPhoneNumberLink}
                  href={cta.href}
                  target={cta.target}
                />
              </PhoneNumberLink>
            )}
          </Description>
        )}
        <HubspotForm
          form={form}
          source={HUBSPOT_FORM_SOURCE.landing}
        />
      </Form>
      {hasCtaWrapper && (
        <CtaWrapper isMobile>
          {ctaTitleMobile && (<CtaTitleMobile><EditorJSContent content={ctaTitleMobile} /></CtaTitleMobile>)}
          {ctaDescriptionMobile && (
            <CtaDescriptionMobile>
              <EditorJSContent content={ctaDescriptionMobile} />
            </CtaDescriptionMobile>
          )}
          {cta && <Link type="buttonCta" {...cta} />}
        </CtaWrapper>
      )}
    </Wrapper>
  );
};

export default SidebarForm;
