import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { fetchRelatedPosts } from '../../../lib/utils';
import { AllPosts, RelatedImage, RelatedPost, RelatedPostWrapper, RelatedTitle, RelatedWrapper } from '../styles';

export const RelatedPosts = ({ postId }) => {
    const [relatedPosts, setRelatedPosts] = useState([]);

    useEffect(() => {
        const fetchRelated = async () => {
            try {
                const related = await fetchRelatedPosts(postId);
                setRelatedPosts(related);
            } catch (error) {
                console.error('Failed to fetch related posts:', error);
            }
        };

        fetchRelated();
    }, [postId]);

    return (
        relatedPosts.length > 0 && (
            <RelatedWrapper>
                <h2>Artículos relacionados</h2>
                <RelatedPostWrapper>
                    {relatedPosts.map((post, index) => (
                        <Link href={`/blog/${post.slug}`} key={index}>
                            <RelatedPost key={post.id}>
                                {post.featured_image && <RelatedImage src={post.featured_image} alt={post.title} />}
                                <RelatedTitle>{post.title}</RelatedTitle>
                            </RelatedPost>
                        </Link>
                    ))}
                </RelatedPostWrapper>
                <Link href={`/blog`}>
                    <AllPosts>Ver todos los artículos</AllPosts>
                </Link>
            </RelatedWrapper>
        )
    );
};

export default RelatedPosts;
