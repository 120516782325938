import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { OverlayProvider } from '@react-aria/overlays';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import styled from 'styled-components';
import { WpHeader } from '../header/header';


const Footer = dynamic(() => import('../footer/footer'));

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  @media screen and (min-width: 1128px) {
    max-width: 1128px;
    padding: 0;
  }
`;


export const BlogLayout = ({ children, pageProps }) => {
  const router = useRouter();
  const { pathname } = router;
  const lang = pathname.startsWith('/ca') ? 'ca' : 'es';
  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang]);

  return (
    <OverlayProvider>
      <Script
        src="https://js.hsforms.net/forms/v2.js"
        strategy="beforeInteractive"
        id="hubspotFormScript"
      />
      <Script
        src="/js/jquery-3.6.0.slim-min.js"
        strategy="afterInteractive"
        id="jquery"
      />
      <WpHeader {...pageProps} />
      <Main>{children}</Main>
      <Footer {...pageProps.footer} />
    </OverlayProvider>
  );
};

export default BlogLayout;